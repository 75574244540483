import React, { useState } from "react"
import axios from "axios"

import content from '../../content/subscribe.yaml'

// https://docs.getform.io/guides/gatsby/

const ContactForm = () => {

  const [serverState, setServerState] = useState({
    submitting: false,
    status: null
  });
  
  const [message, setMessage] = useState([]);

  const handleServerResponse = (ok, msg, form) => {
    setServerState({
      submitting: false,
      status: { ok, msg }
    });
    if (ok) {
      form.reset();
    }
  };
  
  const handleOnSubmit = e => {
    e.preventDefault();
    setServerState({ submitting: true });
    const form = e.target;
    const name = form.name.value;
    const email = form.email.value;
    const msg = form.message.value;
  
    axios({
      method: "post",
      headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' },
      url: "https://85c8pvj3b2.execute-api.eu-central-1.amazonaws.com/default/send-contactform-email",
      data: {
        name: name,
        email: email,
        message: msg
      }
    })
    .then(function (response) {
      console.log(response);
      handleServerResponse(true, "Thanks!", form);
      setMessage("Vielen Dank für Ihre Nachricht!");
    })
    .catch(function (error) {
      console.log(error);
      handleServerResponse(false, error.response, form);
      setMessage("Leider ist ein Fehler passiert. Bitte schreiben Sie uns direkt an office (at) nordhof.at");
    });
  };

  return (
    <section id="subscribe">
      <div className="row section-head">
        <div className="twelve columns">
          <h1>{content.title}</h1>
          <p>{content.body}</p>
        </div>
      </div>
  
      <div className="row">
  
        <p><small>{content.small}</small></p>
        <form method="post" onSubmit={handleOnSubmit}>
          <div className="twelve columns">
            <div className="row">
              <div className="cf-label"><label for="name">Name</label></div>
              <div className="cf-input"><input type="text" name="name" id="name" required /></div>
            </div>
            <div className="row">
              <div className="cf-label"><label for="email">Email</label></div>
              <div className="cf-input"><input type="email" name="email" id="email" required /></div>
            </div>
            <div className="row">
              <div className="cf-label"><label for="message">Nachricht</label></div>
              <div className="cf-input"><textarea name="message" id="message" rows="5" required /></div>
            </div>
            <div className="row">
              <input type="submit" value="Senden" />
              &nbsp;&nbsp;
              <input type="reset" value="Löschen" />
            </div>
            <div className="row"><h2>{message}</h2></div>
          </div>
        </form>
  
      </div>
    </section>
  )
}

export default ContactForm;