import React from "react"

import content from '../../content/team.yaml'

export default () =>{

  return (
    <section id="team">
      <div className="row">
        <div className="bgrid-thirds s-bgrid-halves">
            <h1>{ content.title }</h1>            
            { content.members.map((member, index) => 
                <div class="column">
                    <div class="member-block">
                        <img src={ member.picture } alt={ member.name }  />                    
                        <h2>{ member.name }</h2>
                        <h3>{ member.function }</h3>
                        <p>{ member.text }</p>
                    </div>
                </div>
            )}
        </div>
    </div>
    </section>
  )
}
